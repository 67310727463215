import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import {
  Title,
  Section,
  Box,
  LinkContact,
} from "../../components/Core";

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <Section className="position-relative">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col lg="11" xl="10" className="text-center">
              <Box>
                <Title variant="secSm">
                   You need software to support you, not get in your way. Why is Tuotr right for you? Talk to us.
                </Title>

                <Box
                  className="text-center d-flex justify-content-center"
                  mt={["3rem", null, "4rem"]}
                >
                  <div className="d-flex flex-column flex-lg-row justify-content-center">
                    <LinkContact
                      href="mailto:support@tuotr.com"
                      target="_blank"
                      className="mb-2 mb-lg-0"
                    >
                      support@tuotr.com
                    </LinkContact>
                    <span className="divider mx-2 mx-lg-4 d-none d-lg-block">
                      |
                    </span>
                    <LinkContact
                      href="https://www.linkedin.com/company/tuotr/"
                      target="_blank"
                    >
                      linkedin
                    </LinkContact>
                  </div>
                </Box>
              </Box>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Hero;
